<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-title>Configure Game</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="onClose">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
      <div id="container">
        <div class="config">
            <ClubSelect v-if="game.configs.club" v-model="innerGameConfig.club" :clubs="game.configs.club" :readOnly="false" />

            <DistanceSelect v-if="game.configs.distance" v-model="innerGameConfig.distance" :distances="game.configs.distance" :readOnly="false" />
        </div>
      </div>
  </ion-content>

  <ion-footer>
      <ion-toolbar class="footer-toolbar">
        <ion-button expand="full" size="large" @click="onStartGame">
            Start Game
        </ion-button>
      </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonTitle, 
  IonToolbar,
  IonButton,
  IonFooter,
} from '@ionic/vue';

import ClubSelect from "@/components/ClubSelect";
import DistanceSelect from "@/components/DistanceSelect";

export default {

  name: 'GameConfigure',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonFooter,

    ClubSelect,
    DistanceSelect,
  },

  props: {
    game: {
        type: Object,
        default: null,
    },

    gameConfig: {
      type: Object,
      default: () => ({
        club: null,
        distance: null,
      }),
    },
  },


  created() {
    this.innerGameConfig = { ...this.gameConfig };
  },

  data() {
    return {
      innerGameConfig: {},
    };
  },

  computed: {
  },

  methods: {
    onStartGame() {
      this.$emit('startgame', this.innerGameConfig);
    },

    onClose() {
      this.$emit('close');
    }
  },
};
</script>

<style>

</style>