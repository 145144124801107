<template>
  <div ref="chart"></div>
</template>

<script>
const chartist = require('chartist');
require('chartist-plugin-legend');

export default {
    name: 'Chart',

    props: {
        data: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: null,
        },
        type: {
            type: String,
            default: 'Line',
        },
    },

    watch: {
      options: { handler: 'redraw', deep: true },
      data: { handler: 'redraw', deep: true },
    },
    mounted () {
      this.draw()
    },
    computed: {
        hasNoData () {
            return !this.data ||
                !this.data.series ||
                this.data.series.length < 1 ||
                (
                    (this.type !== 'Pie' && !this.options.distributeSeries) &&
                    this.data.series.every(series => {
                    if (Array.isArray(series)) {
                        return !series.length
                    }
                      return !series.data.length
                    })
                );
        },
    },

    methods: {
      draw () {
        this.chart = this.hasNoData ? null : new chartist[this.type](this.$refs.chart, this.data, this.options)
      },
      redraw () {
        this.chart ? this.chart.update(this.data, this.options) : this.draw()
      },
    },
}
</script>

<style scoped lang="scss">
::v-deep .ct-legend {
    position: relative;
    z-index: 10;

    li {
        position: relative;
        padding-left: 23px;
        margin-bottom: 6px;
        font-size: 0.8rem;
        list-style: none;
        text-align: left;
    }

    li:before {
        width: 6px;
        height: 6px;
        position: absolute;
        left: 0;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        top: 0;
        right: 0;
    }

    .ct-series-0:before {
        background-color: red;
        border-color: red;
    }

    .ct-series-1:before {
        background-color: #f05b4f;
        border-color: #f05b4f;
    }
}
</style>