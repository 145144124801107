<template>
  <div class="club-face-stats">
      <h2>Previous Session Stats</h2>

      <ion-slides :pager="true">
          <ion-slide>
            <div class="target">
                <img src="/assets/images/clubface-iron.png" />

                <div v-for="(shotSet, setIndex) in shotSets" :key="setIndex">
                    <div
                        class="shot"
                        v-for="(shot, index) in shotSet"
                        :key="index"
                        :style="`top: calc(${shot.y}% - 4px); left: calc(${shot.x}% - 4px);background: ${colors[setIndex]}`"
                    >
                    </div>
                </div>
            </div>
          </ion-slide>
          <ion-slide>
            <div class="target">
                <img src="/assets/images/clubface-iron.png" />

                <template v-for="(gameSession, index) in gameSessions">
                    <Ellipse v-if="gameSession.data.ellipse" :ellipse="gameSession.data.ellipse" :fill="colors[index]" :key="index" />
                </template>
            </div>
          </ion-slide>
          <ion-slide>
            <div class="target">
                <img src="/assets/images/clubface-iron.png" />

                <div class="overlay">
                    <div><span>{{ quadrants.T }}%</span></div>
                    <div><span>{{ quadrants.C }}%</span></div>
                    <div><span>{{ quadrants.H }}%</span></div>
                </div>
            </div>
          </ion-slide>
          <ion-slide>
              <Chart :data="chartData" :options="chartOptions" />
          </ion-slide>
    </ion-slides>
  </div>
</template>

<script>
const chartist = require('chartist');

import { IonSlides, IonSlide } from '@ionic/vue';
import Ellipse from '@/components/games/target/Ellipse';
import Chart from '@/components/games/charts/Chart';

export default {
    name: 'TargetPracticeStats',

    inject: ['global'],

    components: {
        IonSlides,
        IonSlide,

        Ellipse,
        Chart,
    },

    props: {
        game: {
            type: Object,
            default: null
        },
    },

    data() {
        return {
            colors: [
                'rgba(48, 97, 127, 0.6)',
                'rgba(67, 126, 154, 0.6)',
                'rgba(89, 155, 175, 0.6)',
                'rgba(115, 180, 192, 0.6)',
                'rgba(144, 203, 207, 0.6)',
                'rgba(177, 224, 222, 0.6)',
                'rgba(215, 240, 237, 0.6)',
            ],

            showStats: 0, // 0 = shots, 1 = quadrants, 2 = ellipse
        };
    },

    computed: {
        gameSessions() {
            return this.global.gameSessions.getAllByGameId(this.game?.id);
        },
        shotSets() {
            return this.gameSessions.map((gs) => gs.shots);
        },

        quadrants() {
            const stats = {};
            const shots = this.shotSets.flat();

            ['T', 'C', 'H'].forEach((q) => {
                const val = (shots.filter((s) => s.quadrant === q).length / shots.length) * 100;

                stats[q] = Math.round(val || 0);
            });

            return stats;
        },

        chartData() {
            const xDev = this.gameSessions.map((gs) => gs.data.xDev);
            const yDev = this.gameSessions.map((gs) => gs.data.yDev);
            const labels = Array(xDev.length - 0 + 1).fill().map((item, index) => 1 + index);

            return {
                labels,
                series: [
                    {
                        name: 'Left/Right Variance',
                        data: xDev
                    },
                    {
                        name: 'Top/Bottom Variance',
                        data: yDev
                    }
                ],
            };
        },

        chartOptions() {
            return {
                width: 300,
                height: 240,
                showPoint: true,
                fullWidth: true,
                chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
                lineSmooth: false,
                plugins: [
                    chartist.plugins.legend({
                        position: 'bottom'
                    })
                ],
                // axisX: {showGrid: true, showLabel: true, offset: 0},
                //axisY: {showGrid: false, showLabel: true, offset: 0}
            };
        },
    },

    methods: {
        onTargetClick() {
            let showStats = this.showStats + 1;
            this.showStats = showStats > 2 ? 0 : showStats;
        },

        /**
         * Calculates the quadrant based on a 45deg rotated cross split. E.g.
         * \/
         * /\
         * Returns T, R, B, L
         */
        getQuadrant(x, y) {
            let quadrant = '';

            if (x >= 0) {
                if (y >= x) {
                    quadrant = 'T';
                } else if (y <= (-1 * x)) {
                    quadrant = 'B';
                } else {
                    quadrant = 'R';
                }
            }

            if (x < 0) {
                if (y >= (-1 * x)) {
                    quadrant = 'T';
                } else if (y <= x) {
                    quadrant = 'B';
                } else {
                    quadrant = 'L';
                }
            }

            return quadrant;
        },
    },
}
</script>

<style lang="scss" scoped>
.club-face-stats {
    background: #fdfdfd;
    margin: 0 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 2px 6px 3px #e0e0e0;

    h2 {
        padding: 1rem 1rem 0;
        font-size: 1.2rem;
        margin: 0;
    }
}

ion-slides {
    height: 375px;
}

.target {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        width: 300px;
        height: 300px;
    }
}

.shot {
    position: absolute;
    z-index: 9;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    border: 1px solid white;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    z-index: 9;

    > div {
        width: 90px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
        border-right: 1px solid #000;

        &:last-child {
            border-right: none;
        }
    }
}
</style>