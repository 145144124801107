<template>
  <div class="distance-select">
    <ion-label>Select Distance</ion-label>
    <ion-segment v-model="selected" scrollable :disabled="readOnly">
        <ion-segment-button v-for="distance in distances" :value="distance" :key="distance">
            <ion-label>{{ distance }}yds</ion-label>
        </ion-segment-button>
    </ion-segment>
  </div>
</template>

<script>
import {
    IonSegment,
    IonSegmentButton,
    IonLabel,
} from '@ionic/vue';


export default {
    name: 'DistanceSelect',

    components: {
        IonSegment,
        IonSegmentButton,
        IonLabel,
    },

    props: {
        modelValue: {
            type: [String, Number],
            default: '',
        },
        distances: {
            type: Array,
            default: () =>[],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        modelValue(val) {
            this.selected = val;
        },
        selected: {
            immediate: true,
            handler() {
                this.$emit('update:modelValue', this.selected);
            }
        }
    },

    data() {
        return {
            selected: this.distances[0]
        };
    },
}
</script>

<style lang="scss" scoped>
.distance-select {
    margin: 1rem;
}
</style>