<template>
  <ion-page>
    <template v-if="game">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ game.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <div id="container">
          <div class="info">
              {{ game.description }}
          </div>

          <div class="stats">
            <component
              :is="game.configs.statsComponent"
              :game="game"
            />

          </div>
        </div>
      </ion-content>

      <ion-footer>
        <ion-toolbar class="footer-toolbar">
          <ion-button v-if="hasConfigs" expand="full" size="large" @click="onConfigureGame">
            Configure Game
          </ion-button>
          <ion-button v-else expand="full" size="large" @click="onStartGame({})">
            Start Game
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </template>

    <ion-modal :is-open="isConfigureOpen">
      <GameConfigure v-if="game" :game="game" @startgame="onStartGame" @close="onModalDismiss"></GameConfigure>
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonFooter,
  IonButton,
  IonModal,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';

import TargetPracticeStats from '@/components/games/stats/TargetPracticeStats';
import ClubFaceStats from '@/components/games/stats/ClubFaceStats';
import NumberStats from '@/components/games/stats/NumberStats';

import GameConfigure from '@/components/games/GameConfigure';

export default {
  name: 'GameDetails',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonFooter,
    IonButton,
    IonModal,

    GameConfigure,
    TargetPracticeStats,
    ClubFaceStats,
    NumberStats,
  },

  setup() {

    return {
      golfSharp
    };
  },

  created() {
  },

  data() {
    return {
      isConfigureOpen: false,
    };
  },

  computed: {
    gameId() {
      return parseInt(this.$route.params.id, 10);
    },

    game() {
      return this.global.games.getById(this.gameId);
    },

    hasConfigs() {
      return !!(this.game && this.game.configs && (this.game.configs.distance || this.game.configs.club));
    },

    gameSessions() {
      return this.global.gameSessions.getAllByGameId(this.gameId);
    },

    gameSessionData() {
      return this.gameSessions.map((gs) => gs.data);
    },

    gameSessionShots() {
      return this.gameSessions.map((gs) => gs.shots);
    },
  },

  methods: {

    onConfigureGame() {
      if (this.hasConfigs) {
        this.isConfigureOpen = true;
      } else {
        this.onStartGame({});
      }
    },

    onStartGame(config) {
      const gameSession = this.global.gameSessions.createGameSession(this.game.id, null, null, config);

      this.isConfigureOpen = false;

      this.$router.push({
        name: 'GameSession',
        params: {
          id: gameSession.id,
        },
      });
    },

    onModalDismiss() {
      this.isConfigureOpen = false;
    },
  },
}
</script>

<style scoped lang="scss">
  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }

  .stats {
    margin-top: 2rem;
  }
</style>