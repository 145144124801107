<template>
  <div class="number-stats">
      <h2>Previous Session Stats</h2>

      <ion-slides :pager="true">
        <ion-slide>
          <Chart :data="chartData" :options="chartOptions" />
        </ion-slide>
      </ion-slides>
  </div>
</template>

<script>
import { IonSlides, IonSlide } from '@ionic/vue';
import Chart from '@/components/games/charts/Chart';

export default {
    name: 'NumberStats',

    inject: ['global'],

    components: {
        IonSlides,
        IonSlide,

        Chart,
    },

    props: {
        game: {
            type: Object,
            default: null
        },
    },

    data() {
        return {
        };
    },

    computed: {
        gameSessions() {
            return this.global.gameSessions.getAllByGameId(this.game?.id);
        },
        shotSets() {
            return this.gameSessions.map((gs) => gs.shots);
        },

        chartData() {
            const data = this.gameSessions.map((gs) => gs.data.points || gs.shots.length);
            const labels = Array(data.length - 0 + 1).fill().map((item, index) => 1 + index);

            return {
                labels,
                series: [
                    {
                        data
                    }
                ],
            };
        },

        chartOptions() {
            return {
                width: 300,
                height: 240,
                showPoint: true,
                fullWidth: true,
                chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
                lineSmooth: false,
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.number-stats {
    background: #fdfdfd;
    margin: 0 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 2px 6px 3px #e0e0e0;

    h2 {
        padding: 1rem 1rem 0;
        font-size: 1.2rem;
        margin: 0;
    }
}

ion-slides {
    height: 375px;
}
</style>